
import { defineComponent, reactive, ref, toRefs } from "vue"
import { Product } from "../../types"
import { deleteProduct } from "@/composables/useProducts"
import router from "@/router"
import AdminDialog from "@/components/admin/AdminDialog.vue"

interface ComponentState {
  popupNavi: boolean
}

export default defineComponent({
  name: "AdminProductListRow",
  components: {
    AdminDialog,
  },
  props: {
    product: {
      type: Object as () => Product,
      required: true,
    },
  },
  setup(props) {
    const state = reactive<ComponentState>({
      popupNavi: false,
    })

    const agreementDialog = ref<boolean>(false)

    const agreementDialogDeleteComplete = ref<boolean>(false)
    console.log("agreementDialogDeleteComplete" + agreementDialogDeleteComplete.value)

    const onCancel = () => {
      agreementDialog.value = false
      state.popupNavi = false
    }

    const onItemDelete = async (): Promise<void> => {
      await deleteProduct(props.product.id!)
      agreementDialog.value = false
      agreementDialogDeleteComplete.value = true
    }

    const onDeleteComplete = () => {
      router.push({ name: "AdminProductList" })
    }

    return {
      state,
      agreementDialog,
      agreementDialogDeleteComplete,
      onCancel,
      onItemDelete,
      onDeleteComplete,
    }
  },
})

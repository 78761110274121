
import { computed, defineComponent, onMounted } from "vue"
import { productsState, getAndSetProducts, deleteProduct } from "@/composables/useProducts"
import AdminButton from "@/components/admin/AdminButton.vue"
import AdminItemListRow from "@/components/admin/AdminItemListRow.vue"

export default defineComponent({
  name: "AdminItemList",
  components: {
    AdminButton,
    AdminItemListRow,
  },
  props: {},
  setup: function () {
    onMounted(() => {
      getAndSetProducts()
    })

    return {
      products: computed(() => productsState.products),
      loading: computed(() => productsState.loading),
    }
  },
})
